
$(window).scroll(function(){

  $('#returnToTop').each(function(){

    if(isScrolledIntoView($(this))){

     	$('#fb-bouton').fadeOut();

    }

    else{

    	 $('#fb-bouton').fadeIn();

    }

  });

});



function isScrolledIntoView(elem){

    var $elem = $(elem);

    var $window = $(window);



    var docViewTop = $window.scrollTop();

    var docViewBottom = docViewTop + $window.height();



    var elemTop = $elem.offset().top;

    var elemBottom = elemTop + $elem.height();



    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    elem.preventDefault();

}