$(document).on('click', '#modele #modal-liste .slick-slider .slick-list .slick-track .slick-slide.with-post', function (e) {
	var this_id = $(this).attr('id');
	$('#modele #modal-liste .slick-slider .slick-list .slick-track .slick-slide .clic').removeClass('clic');
    $(this).toggleClass('clic');
	$('.'+this_id).toggleClass('clic');
	$('html').css({'overflow-y' : 'hidden'});
	$('header, #list-num-rea, footer').hide();
});
$(document).on('click', '#modele #modal-liste .modal-contenu .close-modal', function () {
	$('#modele #modal-liste .modal-contenu').removeClass('clic');
	$('#modele #modal-liste .slick-slider .slick-list .slick-track .slick-slide .clic').removeClass('clic');
	$('html').css({'overflow-y' : 'initial'});
	$('header, #list-num-rea, footer').show();
});

$("#modele #modal-liste .modal-contenu .cc-carousel-in-modal .carousel-indicators li").click(function() {
    $('html,body').animate({
        scrollTop: $(".modal-contenu").offset().top},
        'slow');
});

var widthz = window.innerWidth;
$('#headMenu').hide();
$('#pop-vid-services').hide();
$(document).on('click', 'header .corps .header-content#nav #navHam', function () {
	$(this).toggleClass('active');
	$('#headMenu, #fb-bouton-mobile').fadeToggle();
	$('html, body, header, #logo-for-menuActive').toggleClass('menu-active');
});
$(document).on('click', '#services .corps .grid-services .grid-row#row-deux .with-row-un#left', function () {
	$(this).toggleClass('active');
	$('#pop-vid-services, #list-num-rea').fadeToggle();
	$('html, body').toggleClass('menu-active');
});
$(document).on('click', '.close-vid', function () {
	$('#pop-vid-services').fadeToggle();
	$('html, body').removeClass('menu-active');
});

$("#returnToTop").click(function() {
    $('html,body').animate({
        scrollTop: $("#topReturn").offset().top},
        'slow');
});

$('.panVid').hide();
$(document).on('click', '.vid-play', function (e) {
	var this_id = $(this).attr('id');
	$('.'+this_id).fadeIn();
    
	$('html').css({'overflow-y' : 'hidden'});
	$('header').hide();
});
$(document).on('click', '.close-vid-pan', function (e) {
	$('.panVid').fadeOut();
    
	$('html').css({'overflow-y' : 'initial'});
	$('header').show();
});

$('#gform_fields_1').append('<div class="clear"></div>');