var stickyTop = $('header').offset().top + 5;
$(window).on( 'scroll', function(){

	if ($(window).scrollTop() >= stickyTop) {
		$('header').addClass('head-is-Scroll');
		$('.grille-deco').addClass('head-is-Scroll');
	} else {
	}

});

function checkScroll(){
	$(window).on( 'scroll', function(){
		if ($(window).scrollTop() >= 5) {
			$('header').addClass('head-is-Scroll');
			$('.grille-deco').addClass('head-is-Scroll');
		} else {
			$('header').removeClass('head-is-Scroll');
			$('.grille-deco').removeClass('head-is-Scroll');
		}
	});
}
$(document).ready(function() {
	checkScroll();
	$(window).scroll(checkScroll);
});

$('#headMenu').wrapInner('<div id="wrap-menu"></div>');