

var $group = $('#liste-rea .corps #rea-list .rea-row');

$("#loadMore").click(function() {
    // Prevent event if disabled
    if ($(this).hasClass('disable')) return;

    var $hidden = $group.filter(':hidden:first').addClass('active');
    if (!$hidden.next('#liste-rea .corps #rea-list .rea-row').length) {
        $(this).addClass('disable');
    }
});