$('.modal-by-modal').slick({
	slidesToShow: 5,
	infinite: false,
	responsive: [
	 {
      breakpoint: 1175,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: true
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
      {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});
