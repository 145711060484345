// Resize

window.onload = function() {
function resize(e) {
	var widths = window.innerWidth;
	var heights = window.innerHeight;
	var widths_corps = $('.corps').outerWidth();
	var dif_screen_corps = widths - widths_corps;
	var side_width = (dif_screen_corps / 2) - 8;
	var count_modalWithP = $('.with-post').length;
	var header_height = $('header').outerHeight();
	var support_intro_home = $('#intro-home-support').outerHeight();
	var support_intro_apropos = $('#intro-apropos-support').outerHeight();
	var support_intro_sing_rea = $('#support-sing-rea-intro').outerHeight();



	var cal = 50% + 1.572+'vw';

	var corps_grille = widths - (side_width * 2);
	var vingtcing_of = (25 * corps_grille) / 100;
	
	$('#support-head').css('height', header_height);
	$('.grille-deco').css({'top' : header_height, 'height' : heights});
	$('.grille-deco').first().css({'left' : side_width});
	$('.grille-deco').last().css({'right' : side_width});
	$('#g2').css('left', vingtcing_of + side_width);
	$('#g4').css('right', vingtcing_of + side_width);
	
	$(".boite-blanche .boite-int").css({'margin-left' : side_width});
	$(".modal-contenu").css({'height' : heights});
	$(".boite-blanche .boite-int h5 span").css({'left' : - side_width, 'width' : side_width - 25});
	$("#intro-rea-sing .corps .contenu .type .bar").css({'left' : - side_width, 'width' : side_width - 25});

	
	$("#services .corps .grid-services .grid-row#row-deux .with-row-un#right").css('width', '25%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
	$("#special-left").css({'width' : side_width, 'left' : - side_width});
	$("#intro-services .corps .boite-blanche-serv .box-int").css({'margin-left' : side_width});

	// Intro contact
	$("#intro-contact .corps #global-infos").css('width', '30.74vw').css({'width' : '+=' + side_width, 'left' : - side_width - 10});

	// centre service
	$("#rect").css('width', '25%').css({'width' : '+=' + side_width, 'margin-left' : - side_width});
	$("#carre").css('width', side_width);
	

	$("#intro-apropos").css('height', heights - header_height);
	$(".panVid").css('height', heights);
	$("#intro-apropos").css('height', heights - header_height - support_intro_apropos);

	// A PROPOS
	$("#list-about .corps #row-un #left #block-vert-left").css({'width' : side_width, 'left' : - side_width});
	$("#list-about .corps #row-trois #block-vert-right").css({'width' : side_width});
	$("#list-about .corps #row-deux #right-deux").css('width', '25%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
	$("#list-about .corps #row-trois").css('width', '100%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
	$("#list-about .corps #row-quatre #right-deux").css('width', '25%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
	$("#list-about .corps #row-trois #line-un").css('width', '100%').css({'width' : '-='+side_width+'px'});


	$("#cc-slideshow-intro .corps #cc-slideshow .carousel-inner .item .boite-blanche").css('width', '38.438vw').css({'width' : '+=' + side_width, 'left' : - side_width});
	$("#intro-apropos .corps #cc-slideshow-apropos .carousel-inner .item .boite-blanche").css('width', '38.438vw').css({'width' : '+=' + side_width, 'left' : - side_width});
	if(widths > 991){
		$("#cc-slideshow-intro").css('height', heights - header_height - support_intro_home);
		$("#intro-apropos").css('height', heights - header_height - support_intro_apropos);
		$("#cc-reaSing-carou").css('height', heights - header_height - support_intro_sing_rea);
		$("#intro-rea-sing .corps #boite-blanc").css('width', '58.073vw').css({'width' : '+=' + side_width, 'left' : - side_width});
		$("#services .corps .grid-services .grid-row#row-un .with-row#left").css('width', '25%').css({'width' : '+='+side_width+'px', 'margin-left' : - side_width});
		$("#services .corps .grid-services .grid-row#row-deux .with-row-un#right").css('width', '25%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
		$("#services .corps .grid-services .grid-row#row-deux .with-row-deux#right").css('width', '25%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
		$("#services .corps .grid-services .grid-row#row-deux .with-row-deux#centre").css('width', '25%').css({'margin-right' : 'initial'});
		$('header .corps .header-content#nav ul').css({'width' : 'initial', 'top' : 'initial'}).css({'width' : 'initial', 'margin-right' : 'initial', 'margin-left' : 'initial'});;
		$('#headMenu').css({'height' : 'initial'});
		$("#list-about .corps #row-un #right").css('width', '50%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
		$("#list-about .corps #row-un #left").css('width', '50%').css({'margin-left' : 'initial'});
		$("#list-about .corps #row-deux #left-un").css('width', '25%').css({'width' : '+='+side_width+'px', 'margin-left' : - side_width});
		$("#list-about .corps #row-trois #line-deux #left").css('width', '50vw').css({'width' : '', 'margin-left' : 'initial'});
		$("#list-about .corps #row-cinq #left").css('width', '50%').css({'width' : '+='+side_width+'px', 'margin-left' : - side_width});
		$("#list-about .corps #row-cinq #right").css('width', '50%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
		$("#intro-services .corps .boite-blanche-serv").css('width', '58.334vw').css({'width' : '+=' + side_width, 'left' : - side_width});
		$("#reno-present .corps .boite-blanche-serv").css('width', '58.334vw').css({'width' : '+=' + side_width, 'right' : - side_width});
		$("#service-present .corps #right #img").css('width', '100%').css({'width' : '+=' + side_width, 'margin-right' : - side_width});
		$("#reno-present .corps #left #img").css('width', '45.834vw').css({'width' : '+=' + side_width, 'margin-left' : - side_width});
	} else if(widths < 992 && widths > 767){
		$("#cc-slideshow-intro").css('height', 400);
		$("#cc-reaSing-carou").css('height', 400);
		$("#intro-apropos").css('height', 400);
		$("#intro-rea-sing .corps #boite-blanc").css('width', '58.073vw').css({'width' : '+=' + side_width, 'left' : - side_width});
		$("#services .corps .grid-services .grid-row#row-un .with-row#left").css('width', '0%').css({'width' : '+='+side_width+'px', 'margin-left' : - side_width});
		$("#services .corps .grid-services .grid-row#row-deux .with-row-un#right").css('width', '40%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
		$("#services .corps .grid-services .grid-row#row-deux .with-row-deux#right").css('width', '15%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
		$("#services .corps .grid-services .grid-row#row-deux .with-row-deux#centre").css('width', '25%').css({'margin-right' : 'initial'});
		$('header .corps .header-content#nav ul').css({'width' : 'calc(100%)', 'top' : header_height}).css({'width' : '+='+side_width * 2+'px', 'margin-right' : - side_width, 'margin-left' : - side_width});;
		$('#headMenu').css({'height' : heights - header_height});
		$("#list-about .corps #row-un #right").css('width', '100%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
		$("#list-about .corps #row-un #left").css('width', '0%').css({'width' : '+='+side_width+'px', 'margin-left' : - side_width});
		$("#list-about .corps #row-deux #left-un").css('width', '0').css({'width' : '+='+side_width+'px', 'margin-left' : - side_width});
		$("#list-about .corps #row-trois #line-deux #left").css('width', '75%').css({'width' : '+='+side_width+'px', 'margin-left' : - side_width});
		$("#list-about .corps #row-cinq #left").css('width', '0%').css({'width' : '+='+side_width+'px', 'margin-left' : - side_width});
		$("#list-about .corps #row-cinq #right").css('width', '100%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
		$("#intro-services .corps .boite-blanche-serv").css('width', '70vw').css({'width' : '+=' + side_width, 'left' : - side_width});
		$("#reno-present .corps .boite-blanche-serv").css('width', '70vw').css({'width' : '+=' + side_width, 'right' : - side_width});
		$("#service-present .corps #right #img").css('width', '100%').css({'width' : '+=' + side_width, 'margin-right' : - side_width});
		$("#reno-present .corps #left #img").css('width', '35vw').css({'width' : '+=' + side_width, 'margin-left' : - side_width});
	} else{
		$("#cc-slideshow-intro").css('height', heights - header_height - 60);
		$("#intro-apropos").css('height', heights - header_height - 60);
		$("#cc-reaSing-carou").css('height', heights - header_height - 60);
		$("#intro-rea-sing .corps #boite-blanc").css({'width' : '100%'}).css({'width' : '+=' + side_width * 2, 'left' : - side_width, 'right' : - side_width});
		$("#services .corps .grid-services .grid-row#row-un .with-row#left").css('width', '0%').css({'width' : '+='+side_width+'px', 'margin-left' : - side_width});
		$("#services .corps .grid-services .grid-row#row-deux .with-row-un#right").css('width', '25%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
		$("#services .corps .grid-services .grid-row#row-deux .with-row-deux#right").css('width', '0');
		$("#services .corps .grid-services .grid-row#row-deux .with-row-deux#centre").css('width', '25%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
		$('header .corps .header-content#nav ul').css({'width' : 'calc(100%)', 'top' : header_height}).css({'width' : '+='+side_width * 2+'px', 'margin-right' : - side_width, 'margin-left' : - side_width});
		$('#headMenu').css({'height' : heights - header_height});
		$("#list-about .corps #row-un #right").css('width', '100%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
		$("#list-about .corps #row-un #left").css('width', '0').css({'width' : '+='+side_width+'px', 'margin-left' : - side_width});
		$("#list-about .corps #row-trois #line-deux #left").css('width', '75%').css({'width' : '+='+side_width * 2+'px', 'margin-left' : - side_width});
		$("#list-about .corps #row-deux #left-un").css('width', '0').css({'width' : '+='+side_width+'px', 'margin-left' : - side_width});
		$("#list-about .corps #row-cinq #left").css('width', '0').css({'width' : '+='+side_width+'px', 'margin-left' : - side_width});
		$("#list-about .corps #row-cinq #right").css('width', '100%').css({'width' : '+='+side_width+'px', 'margin-right' : - side_width});
		$("#intro-services .corps .boite-blanche-serv").css('width', '75vw').css({'width' : '+=' + side_width, 'left' : - side_width});
		$("#reno-present .corps .boite-blanche-serv").css('width', '75vw').css({'width' : '+=' + side_width, 'right' : - side_width});
		$("#service-present .corps #right #img").css('width', '0%').css({'width' : '+=' + side_width, 'margin-right' : - side_width});
		$("#reno-present .corps #left #img").css('width', '20vw').css({'width' : '+=' + side_width, 'margin-left' : - side_width});
	}
}
resize();

window.onresize = function() {
	resize();
};
}

$('.rea-row').append('<div class="clear"></div>');
